<template>
    <div>
        <div class="card-body" @click="show">
            <article class="d-flex flex-row">
                <h4 class="h4 card-title m-0" v-text="item.question">
                </h4>

                <div class="d-flex justify-content-end faq-header">
                    <span :class="classes"></span>
                </div>
            </article>
            <div class="card-text mt-3" v-show="opened" v-html="item.answer"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['item'],
        data() {
            return {
                opened: false
            }
        },
        methods: {
            show() {
                this.opened = !this.opened
            }
        },
        computed: {
            classes() {
                return this.opened ? 'fa fa-minus fa-lg fa-green' : 'fa fa-plus fa-lg fa-green';
            }
        },

    }
</script>
