<template>
    <div class="container">
        <div class="row">
            <div class="col col-md-offset-2 w-100 p-3">
                <div class="card w-100 mb-3" v-for="faq in faqs" :key="faq.id">
                    <faq :item="faq"></faq>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'faqs',
        ],
    }
</script>
